import React, { useEffect, useState, useRef } from "react";
import CountUp from "react-countup";
import client from "../image/clients.png";
import growth from "../image/growth.png";
import brands from "../image/brand.png";
import img1 from "../image/img1.jpeg";
import img2 from "../image/img2.jpeg";
import img3 from "../image/img3.jpeg";
import img4 from "../image/img4.jpeg";
import img5 from "../image/img5.jpeg";
import img6 from "../image/img6.jpeg";
import img7 from "../image/img7.jpeg";
import img8 from "../image/img8.jpeg";
import group1 from "../image/Group1.png";
import group2 from "../image/Group2.png";
import group3 from "../image/Group3.png";
import group4 from "../image/Group4.png";
import group5 from "../image/Group5.png";
import html from "../image/html.png";
import css from "../image/css.png";
import js from "../image/js.png";
import react from "../image/react.png";
import node from "../image/node.png";
import php from "../image/php.png";
import laravel from "../image/laravel.png";
import mysql from "../image/mysql.png";
import mongodb from "../image/mongodb.png";
import wordpress from "../image/wordpress.png";
import spedify from "../image/spadyfy.png";
import woocom from "../image/woocom.png";
import webdev2 from "../image/webdev2.jpeg";
import logo from "../image/dixilogo.png";
import "./style.css";
import "./footer.css";
import Slider from "./Slider";
import { IoMdClose } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import Typewriter from "typewriter-effect";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const Home = ({ onInit }) => {
  const typewriterRef = useRef(["wnqndwnwjqiodjoiwqjoij"]);
  //useEffect
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
    if (typewriterRef.current && onInit) {
      onInit(typewriterRef.current);
    }
  }, [onInit]);
  const [model, setModel] = useState(false);
  const [phone, setPhone] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      setModel(true); // Open the model after 5 seconds
    }, 5000);

    return () => clearTimeout(timer); // Clean up timer on unmount
  }, []);

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    cname: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await axios.post(
        `https://admin.preonlinetest.in/api/query-fotm?subject=digixtron&message=fistname:${formData.fname}, lastname:${formData.lname}email:${formData.email}, mobile:${phone}, cname${formData.cname}, message:${formData.message}`
      );
    } catch (error) {
      if (formData === null) {
        alert("kldjiowqioqwj");
      } else {
      }
      alert("Your form has been successfully submitted");

      setFormData({
        fname: "",
        lname: "",
        email: "",
        mobile: "",
        cname: "",
        message: "",
      });

      setModel(false);
      console.log(error);
    }
  };

  const imageData = [
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
    { img: img6 },
    { img: img7 },
    { img: img8 },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const handleTypewriterInit = (typewriter) => {
    typewriter
      .typeString("Hello World!")
      .callFunction(() => {
        console.log("String typed out!");
      })
      .pauseFor(2500)
      .deleteAll()
      .callFunction(() => {
        console.log("All strings were deleted");
      })
      .start();
  };

  return (
    <div className="w-full">
      <div className="relative">
        <nav className="bg-white border-gray-200 dark:bg-gray-900 shadow-md fixed w-full top-0 z-50">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a
              href="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img src={logo} className="h-12" alt="Flowbite Logo" />
            </a>
            <button
              data-collapse-toggle="navbar-default"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
            <div
              className="hidden w-full md:block md:w-auto"
              id="navbar-default"
            >
              <ul className="font-medium text-2xl mr-20 flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                <li>
                  <a
                    href="https://www.instagram.com/digixtron_/"
                    target="_blank"
                    className="block py-2 px-3 text-black z-[9999] bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 to-black md:dark:text-blue-500"
                    aria-current="page"
                  >
                    <span>
                      <FaInstagram />
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com"
                    target="_blank"
                    className="block py-2 px-3 rounded  md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-black md:dark:hover:text-blue-500 dark:hover:bg-gray-700 "
                  >
                    <span>
                      <FaYoutube />
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/digixtro"
                    target="_blank"
                    className="block py-2 px-3 rounded  md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-black md:dark:hover:text-blue-500 dark:hover:bg-gray-700 "
                  >
                    <span>
                      <FaFacebook />
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/digi-xtron-bb461326b/"
                    target="_blank"
                    className="block py-2 px-3 rounded  md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-black md:dark:hover:text-blue-500 dark:hover:bg-gray-700 "
                  >
                    <span>
                      <FaLinkedin />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="lg:w-[90%] sm:w-full mx-auto lg:mt-24 sm:mt-16">
          <div className="top-text sm:mx-5 lg:mx-0 flex gap-10 text-2xl"></div>

          <section className="bg-white dark:bg-gray-900">
            <div className="grid max-w-screen-xl py-1 mx-auto lg:gap-8 xl:gap-0 lg:py-2 lg:grid-cols-12">
              <div className="mr-auto mt-10 lg:text-start sm:text-center sm:mx-10 lg:col-span-6">
                <h3
                  className="max-w-4xl mb-4 text-lg font-bold leading-none md:text-3xl xl:text-4xl text-[#b56e5c] tracking-wide"
                  data-aos="fade-right"
                >
                  We Are the Brand Builders.
                </h3>
                <h3
                  className="max-w-4xl mb-4 text-lg font-bold leading-none md:text-3xl xl:text-4xl text-[#1571cc] tracking-wide"
                  data-aos="fade-right"
                >
                  <Typewriter
                    options={{
                      strings: [
                        "Hire Leading Website Development Services Company To Make Your Business Website Now!!",
                        "Fully Customized and Responsive Website Development Services for Your Needs",
                        "Custom app development tailored to your needs",
                      ],

                      autoStart: true,
                      loop: true,
                      delay: 50,
                    }}
                  />
                </h3>
                <div className="text-xl text-[#991c5f] mt-5">
                  <Typewriter
                    options={{
                      strings: [
                        " App Development",
                        "Web Development",
                        "Digital Marketing",
                        "Graphic Designing",
                      ],

                      autoStart: true,
                      loop: true,
                      delay: 75,
                    }}
                  />
                </div>
                <p className="max-w-2xl my-10 font-medium text-gray-600 lg:mb-8 md:text-lg lg:text-lg dark:text-gray-600">
                  Fully Customized and Responsive Website Development Services
                  for Your Needsnality, the latest SEO techniques, and a touch
                  of magic.
                </p>
                <button
                  type="button"
                  class="text-white bg-[#2869e8] hover:text-white border-[1px] border-[#1571CC] font-semibold text-lg  hover:bg-blue-800 px-6 py-2 rounded-lg"
                  onClick={() => setModel(!model)}
                >
                  Book a Free Consultation
                </button>
              </div>
              <div className="hidden lg:mt-0 lg:col-span-6 lg:flex ">
                <img className="h-[30rem] w-full " src={webdev2} alt="mockup" />
              </div>
            </div>
          </section>
        </div>

        {/* slider */}

        {/* btn */}

        <div className="counter-section">
          <div className="main w-full bg-[#001a34] h-auto py-[3%] my-10">
            <div className="elementer-container w-full flex mx-auto relative">
              <div className="w-full grid grid-cols-12 text-white lg:gap-1/2 sm:gap-5">
                <div className="lg:col-span-4 sm:col-span-12 flex justify-center border-r-2">
                  <div className="flex flex-col items-center text-center gap-2">
                    <img className="h-14" src={client} alt="client" />
                    <h2 className="text-xl font-bold">30%</h2>
                    <p className="text-lg">Client Repeat Rate</p>
                  </div>
                </div>
                <div className="lg:col-span-4 sm:col-span-12 flex justify-center  border-r-2">
                  <div className="flex flex-col items-center text-center gap-2">
                    <img className="h-14" src={growth} alt="client" />
                    <h2 className="text-xl font-bold">75%</h2>
                    <p className="text-lg">
                      Client Revenue Growth via Websites
                    </p>
                  </div>
                </div>
                <div className="lg:col-span-4 sm:col-span-12 flex justify-center">
                  <div className="flex flex-col items-center text-center gap-2">
                    <img className="h-14" src={brands} alt="client" />
                    <h2 className="text-2xl font-bold">
                      <CountUp end={200} />+
                    </h2>
                    <p className="text-lg">Top Brands Served</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-10">
          <img src="" alt="" />
        </div>

        <div className="review lg:w-[80%] sm:w-[96%] mx-auto">
          <div className="heading text-[30px] font-[700] text-center">
            <h2 className=" text-[#1571cc] leading-normal">Our Client Says</h2>
          </div>
          <div className="my-20 w-full">
            <Slider />
          </div>
        </div>

        <div className="box w-full bg-[#f3f9ff] py-5 ">
          <div className="w-[90%] mx-auto">
            <div className="heading  font-[700] text-center" data-aos="fade-up">
              <h2 className="text-[#1571cc] lg:leading-[2rem] sm:leading-7 lg:w-[70%] sm:w-full mx-auto lg:mt-5 sm:mt-3 lg:text-[25px] sm:text-[15px]">
                Target your potential Customers with Strategic digital marketing
              </h2>
            </div>
          </div>
          <div className="box w-[90%] mx-auto my-10">
            <div className="grid grid-cols-12 gap-5 shadow-xl overflow-hidden">
              <div className="lg:col-span-4 sm:col-span-12 bg-white relative group hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-105 border-[1px]">
                <div className="">
                  <h2 className="text-xl text-[#656668] text-start py-2 px-5 font-[700] group-hover:text-black">
                    Social Media
                  </h2>
                  <p className="text-start px-5 py-5">
                    Social Media has changed how brands communicate with their
                    audiences forever. <br />
                    <br />
                    Social Strategy Development <br />
                    Social Media Consulting <br />
                    Social Media Advertising
                  </p>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    1
                  </h1>
                </div>
              </div>
              <div className="lg:col-span-4 sm:col-span-12 hover:text-black group bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-105 border-[1px]">
                <div className="">
                  <h2 className="text-xl text-[#656668] text-start py-2 px-5 font-[700] group-hover:text-black">
                    Search Engine Optimization
                  </h2>
                  <p className="text-start px-5 py-5">
                    Billions of web browsing sessions begin with a search query
                    every day. With more than a billion websites competing for
                    the top spot in search results, it can be difficult to
                    drive. traffic to your site from search engines. <br />
                    <br />
                    Keyword Research <br />
                    Technical SEO <br />
                    Full SEO Audits <br />
                    SEO Consulting
                  </p>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    2
                  </h1>
                </div>
              </div>
              <div className="lg:col-span-4 sm:col-span-12 hover:text-black group bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-105 border-[1px]">
                <div className="">
                  <h2 className="text-xl text-[#656668] text-start py-2 px-5 font-[700] group-hover:text-black ">
                    Digital Content & Video
                  </h2>
                  <p className="text-start px-5 py-5">
                    Content is the king of today's marketing environment. The
                    most successful brands in the world have developed detailed
                    content strategies that help them inspire, entertain, and
                    educate their target audiences. <br />
                    <br />
                    Digital Content Strategy <br />
                    Content Production <br />
                    Content Production <br />
                    Video Production <br />
                    Graphic Design <br />
                    Multi-language Content
                  </p>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    3
                  </h1>
                </div>
              </div>
            </div>
            {/* <div className="grid grid-cols-12 gap-2 shadow-xl mt-2">
              <div className="lg:col-span-6 sm:col-span-12 bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-105 border-[1px] group">
                <div className="">
                  <h2 className="text-xl text-[#656668] text-start py-2 px-5 font-[700] group-hover:text-black">
                    Transparency and Accountability
                  </h2>
                  <p className="text-start px-5 py-5">
                    We value and believe that transparency and accountability
                    should be maintained, and we abide by the values and our
                    every each team member carries the value maintaining your
                    integrity.
                  </p>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    4
                  </h1>
                </div>
              </div>
              <div className="lg:col-span-6 sm:col-span-12 hover:text-black2 bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-105 border-[1px] group">
                <div className="">
                  <h2 className="text-xl text-[#656668] text-start py-2 px-5 font-[700] group-hover:text-black ">
                    Sustainable Relationships
                  </h2>
                  <p className="text-start px-5 py-5">
                    Every client means so much to us and we value your place in
                    our community. We are committed to provide excellence and
                    support in the journey of our client as long as they need
                    us..
                  </p>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    5
                  </h1>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="box w-full bg-[#f3f9ff] py-5 ">
          <div className="w-[80%] mx-auto">
            <div className="heading  font-[700] text-center">
              <h2 className="text-[#1571cc] lg:leading-[2.5rem] sm:leading-[2rem] lg:w-[70%] sm:w-[100%] mx-auto mt-5 lg:text-[35px] sm:text-[25px]">
                Explore What We Can Achieve Together
              </h2>
            </div>
          </div>
          <div className="box w-[90%] mx-auto my-10">
            <div className="grid grid-cols-12 gap-5 ">
              <div className="lg:col-span-4 sm:col-span-12 bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-110">
                <div className="w-[80%]">
                  <h2 className="text-xl text-[#656668] hover:text-black text-start py-10 px-5 font-[700]">
                    Website & Brand Identity Development
                  </h2>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    1
                  </h1>
                </div>
              </div>
              <div className="lg:col-span-4 sm:col-span-12 bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-110">
                <div className="w-[80%]">
                  <h2 className="text-xl text-[#656668] hover:text-black text-start py-10 px-5 font-[700]">
                    Mobile App development
                  </h2>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    2
                  </h1>
                </div>
              </div>
              <div className="lg:col-span-4 sm:col-span-12 bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-110">
                <div className="w-[80%]">
                  <h2 className="text-xl text-[#656668] hover:text-black text-start py-10 px-5 font-[700]">
                    Social Media Branding
                  </h2>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    3
                  </h1>
                </div>
              </div>
              <div className="lg:col-span-4 sm:col-span-12 bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-110">
                <div className="w-[80%]">
                  <h2 className="text-xl text-[#656668] hover:text-black text-start py-10 px-5 font-[700]">
                    Graphic Designing
                  </h2>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    4
                  </h1>
                </div>
              </div>
              <div className="lg:col-span-4 sm:col-span-12 bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-110">
                <div className="w-[80%]">
                  <h2 className="text-xl text-[#656668] hover:text-black text-start py-10 px-5 font-[700]">
                    Paid Online Marketing
                  </h2>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    5
                  </h1>
                </div>
              </div>
              <div className="lg:col-span-4 sm:col-span-12 bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-110">
                <div className="w-[80%]">
                  <h2 className="text-xl text-[#656668] hover:text-black text-start py-10 px-5 font-[700]">
                    Organic Online Promotion by Optimization
                  </h2>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    6
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              type="button"
              class="text-white bg-[#2869e8] hover:text-white border-[1px] border-[#1571CC] font-semibold text-lg  hover:bg-blue-800 px-6 py-2 rounded-lg"
              onClick={() => setModel(!model)}
            >
              Book a Free Consultation
            </button>
          </div>
        </div>

        <div className="project bg-[#f3f9ff] w-full">
          <div className="w-[90%] mx-auto py-5 pt-10">
            <div className="heading lg:text-[35px] sm:text-[25px] font-[700] text-center">
              <h2 className=" text-[#353535] leading-normal lg:mt-5 sm:mt-3">
                Our Service
              </h2>
            </div>
            <div className="grid grid-cols-12 gap-5 mt-10 relative">
              {imageData.map((item) => (
                <div className="lg:col-span-4 sm:col-span-12">
                  <img
                    className="border-[1px] h-[22rem] cursor-pointer duration-[0.5s] hover:scale-105"
                    src={item.img}
                    alt=""
                    onClick={() => setModel(!model)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Our Unmatched */}
        <div className=" Our-Unmatched w-[90%] mx-auto my-10 ">
          <div className="heading lg:text-[35px] sm:text-[25px] font-[700] text-center">
            <h2 className=" leading-[2rem]">
              <span className="text-[#353535] "> Proven To Be</span>{" "}
              <span className="text-[#1571cc]">
                The Best Website Development Service Company
              </span>
            </h2>
          </div>
          <p className="my-5 text-lg text-gray-500">
            To be the best web development service company, design, user
            experience and navigation are the important factors that need to be
            considered for engaging, executing business goals that maximizes
            customer satisfaction. To make website, we follow a process to
            deliver your website in good terms and make it perform well in
            today’s market
          </p>
          <h2 className="text-lg font-bold text-[#404040]">
            Understanding Your Needs The Right Way
          </h2>

          <div className="section-box">
            <div className="top-content mt-10">
              <div className="section grid grid-cols-12 mt-10 gap-5">
                <div className="lg:col-span-4 sm:col-span-12 border-[1px] rounded-xl px-5 py-10 flex flex-col gap-5 shadow-xl main-heder hover-color duration-[0.5s] hover:-translate-y-6 group">
                  <div className="icon">
                    <img className="w-20" src={group1} alt="" />
                  </div>
                  <h2 className="text-2xl font-semibold">
                    Requirement Analysis
                  </h2>
                  <p className="text-gray-500 text-base group-hover:text-black">
                    To turn your requirement into running form, we are committed
                    to the requirements of the users. To meet your specific
                    needs, we tailor cut-edge solutions by gathering clients’
                    requirements, objectives to craft your digital presence.
                  </p>
                </div>
                <div className="lg:col-span-4 sm:col-span-12 border-[1px] rounded-xl px-5 py-10 flex flex-col gap-5 shadow-xl hover-color duration-[0.5s] hover:-translate-y-6 hover:text-black group">
                  <div className="icon">
                    <img className="w-20" src={group2} alt="" />
                  </div>
                  <h2 className="text-2xl font-semibold">
                    Design and Development
                  </h2>
                  <p className="text-gray-500 text-base group-hover:text-black">
                    Our professional team gives a perfect blend of creativity
                    and logic in all spheres of website design and development
                    services. To make your website look attractive, classifying
                    your business goals and meeting expectations of user by
                    giving them a fine experience.
                  </p>
                </div>
                <div className="lg:col-span-4 sm:col-span-12 border-[1px] rounded-xl px-5 py-10 flex flex-col gap-5 shadow-xl hover-color duration-[0.5s] hover:-translate-y-6 group">
                  <div className="icon">
                    <img className="w-20" src={group3} alt="" />
                  </div>
                  <h2 className="text-2xl font-semibold">
                    {" "}
                    Testing and Quality Assurance
                  </h2>
                  <p className="text-gray-500 text-base group-hover:text-black">
                    In a world, where world keep changing, it is important to
                    make sure that your digital presence is intact. We ensure
                    your website is developed and deployed properly by checking
                    it on certain terms to specify the quality of your website.
                  </p>
                </div>
                <div className="lg:col-span-4 sm:col-span-12 border-[1px] rounded-xl px-5 py-10 flex flex-col gap-5 shadow-xl hover-color duration-[0.5s] hover:-translate-y-6 group">
                  <div className="icon">
                    <img className="w-20" src={group4} alt="" />
                  </div>
                  <h2 className="text-2xl font-semibold">
                    Deployment and Launch
                  </h2>
                  <p className="text-gray-500 text-base group-hover:text-black">
                    After going through the testing and deployment phase,
                    website is deployed and launched to check its compatibility
                    in the environment and check how well it can perform in the
                    market as compared to its competitors.
                  </p>
                </div>
                <div className="lg:col-span-4 sm:col-span-12 border-[1px] rounded-xl px-5 py-10 flex flex-col gap-5 shadow-xl hover-color duration-[0.5s] hover:-translate-y-6 group">
                  <div className="icon">
                    <img className="w-20" src={group5} alt="" />
                  </div>
                  <h2 className="text-2xl font-semibold">
                    {" "}
                    Continuous Improvement{" "}
                  </h2>
                  <p className="text-gray-500 text-base group-hover:text-black">
                    We extend our hands more than just making website. We ensure
                    and commit to provide our support in your journey so you can
                    have a smooth path that leads to fulfil your goals through
                    the website.
                  </p>
                </div>
                <div className="lg:col-span-4 sm:col-span-12 border-[1px] rounded-xl px-5 py-10 flex flex-col gap-5 shadow-xl hover-color duration-[0.5s] hover:-translate-y-6 group">
                  <div className="icon">
                    <img className="w-20" src={group5} alt="" />
                  </div>
                  <h2 className="text-2xl font-semibold">Game UI/UX Design</h2>
                  <p className="text-gray-500 text-base group-hover:text-black">
                    We design game UI/UX that matches with your game’s genre,
                    tone, and player expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="project bg-[#000000E5] w-full">
          <div className="w-[90%] mx-auto py-10">
            <div className="heading text-[35px] font-[700] text-center">
              <h2 className="text-white leading-[2rem]">
                Our Technology Arsenal
              </h2>
            </div>
            <div className="w-full lg:flex sm:block gap-10 mt-10">
              <div className="lg:w-[50%] sm:w-full bg-[#56545480] px-5 py-3 text-start">
                <h2 className="border-b-[1px] pb-5 text-white text-2xl font-bold">
                  Frontend :
                </h2>
                <div className="py-5 flex gap-[8%]">
                  <div>
                    <img className="w-8 text-center" src={html} alt="" />
                    <p className="text-white mt-2">HTML5</p>
                  </div>
                  <div>
                    <img className="w-8 items-center" src={css} alt="" />
                    <p className="text-white">CSS3</p>
                  </div>
                  <div>
                    <img className="w-10" src={js} alt="" />
                    <p className="text-white">JavaScript</p>
                  </div>
                  <div>
                    <img className="w-10" src={react} alt="" />
                    <p className="text-white">React.js</p>
                  </div>
                </div>
              </div>
              <div className="lg:w-[50%] sm:w-full sm:mt-5 lg:mt-0 bg-[#56545480] px-5 py-3 text-start">
                <h2 className="border-b-[1px] pb-5 text-white text-2xl font-bold">
                  Backend :
                </h2>
                <div className="p-5 flex gap-12 ">
                  <div>
                    <img className="w-10 h-8" src={node} alt="" />
                    <p className="text-white mt-2">Node.js</p>
                  </div>
                  <div>
                    <img className="w-10 h-10" src={php} alt="" />
                    <p className="text-white">PHP</p>
                  </div>
                  <div>
                    <img className="w-10" src={laravel} alt="" />
                    <p className="text-white">Laravel</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:flex sm:block gap-5 mt-5">
              <div className="lg:w-1/3 sm:w-full bg-[#56545480] px-5 py-3 text-start">
                <h2 className="border-b-[1px] pb-5 text-white text-2xl font-bold">
                  Database :
                </h2>
                <div className="p-5 flex gap-12 ">
                  <div>
                    <img className="w-8 h-8" src={mysql} alt="" />
                    <p className="text-white mt-2">MYSQL</p>
                  </div>
                  <div>
                    <img className="w-8 h-10" src={mongodb} alt="" />
                    <p className="text-white">MongoDB</p>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/3 sm:w-full sm:mt-5 lg:mt-0 bg-[#56545480] px-5 py-3 text-start">
                <h2 className="border-b-[1px] pb-5 text-white text-2xl font-bold">
                  CMS :
                </h2>
                <div className="p-5 flex gap-12 ">
                  <div>
                    <img className="w-10" src={wordpress} alt="" />
                    <p className="text-white">Wordpress</p>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/3 sm:w-full sm:mt-5 lg:mt-0 bg-[#56545480] px-5 py-3 text-start">
                <h2 className="border-b-[1px] pb-5 text-white text-2xl font-bold">
                  E-commerce :
                </h2>
                <div className="p-5 flex gap-12 ">
                  <div>
                    <img className="w-8 h-8" src={spedify} alt="" />
                    <p className="text-white mt-2">Shopify</p>
                  </div>
                  <div>
                    <img className="w-10 h-10" src={woocom} alt="" />
                    <p className="text-white">WooCommerce</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="box w-full bg-[#f3f9ff] py-5 ">
          <div className="w-[90%] mx-auto">
            <div className="heading  font-[700] text-center" data-aos="fade-up">
              <h2 className=" text-[#353535] lg:leading-[2rem] sm:leading-[1.5rem] lg:text-[25px] sm:text-[17px]">
                Target Your Potential Customer With Cloud Analogy Web
                Development Services
              </h2>
              <h2 className="text-[#1571cc] lg:leading-[2rem] sm:leading-7 lg:w-[70%] sm:w-full mx-auto lg:mt-5 sm:mt-3 lg:text-[25px] sm:text-[15px]">
                Empowering Digital Evolution: Pioneering Excellence in Web
                Development
              </h2>
            </div>

            <p className="text-[#404040] lg:text-[20px] sm:text-[14px] font-[600] lg:mt-5 sm:mt-2">
              Cloud Analogy Is The Name that's in demand.
            </p>
          </div>
          <div className="box w-[90%] mx-auto my-10">
            <div className="grid grid-cols-12 gap-2 shadow-xl overflow-hidden">
              <div className="lg:col-span-4 sm:col-span-12 bg-white relative group hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-105 border-[1px]">
                <div className="">
                  <h2 className="text-xl text-[#656668] text-start py-2 px-5 font-[700] group-hover:text-black">
                    Proven Achievements
                  </h2>
                  <p className="text-start px-5 py-5">
                    Cloud Analogy has proven record of delivering exceptional
                    results across diverse industries as we embrace high-end
                    technologies to deliver high-quality solutions.
                  </p>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    1
                  </h1>
                </div>
              </div>
              <div className="lg:col-span-4 sm:col-span-12 hover:text-black group bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-105 border-[1px]">
                <div className="">
                  <h2 className="text-xl text-[#656668] text-start py-2 px-5 font-[700] group-hover:text-black">
                    Customer-Focused Approach
                  </h2>
                  <p className="text-start px-5 py-5">
                    Client is the foremost priority to be considered and we are
                    committed to deliver projects that are user-centric
                    fulfilling the needs of user and helps in achieving business
                    goals.
                  </p>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    2
                  </h1>
                </div>
              </div>
              <div className="lg:col-span-4 sm:col-span-12 hover:text-black group bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-105 border-[1px]">
                <div className="">
                  <h2 className="text-xl text-[#656668] text-start py-2 px-5 font-[700] group-hover:text-black ">
                    Innovation and Creativity
                  </h2>
                  <p className="text-start px-5 py-5">
                    Our team developers keep upgrading themselves with the
                    latest tools & technologies, delivering cutting-edge
                    solutions to outshine the competition.
                  </p>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    3
                  </h1>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-2 shadow-xl mt-2">
              <div className="lg:col-span-6 sm:col-span-12 bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-105 border-[1px] group">
                <div className="">
                  <h2 className="text-xl text-[#656668] text-start py-2 px-5 font-[700] group-hover:text-black">
                    Transparency and Accountability
                  </h2>
                  <p className="text-start px-5 py-5">
                    We value and believe that transparency and accountability
                    should be maintained, and we abide by the values and our
                    every each team member carries the value maintaining your
                    integrity.
                  </p>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    4
                  </h1>
                </div>
              </div>
              <div className="lg:col-span-6 sm:col-span-12 hover:text-black2 bg-white relative  hover-color cursor-pointer rounded-lg overflow-hidden duration-[0.5s] hover:scale-105 border-[1px] group">
                <div className="">
                  <h2 className="text-xl text-[#656668] text-start py-2 px-5 font-[700] group-hover:text-black ">
                    Sustainable Relationships
                  </h2>
                  <p className="text-start px-5 py-5">
                    Every client means so much to us and we value your place in
                    our community. We are committed to provide excellence and
                    support in the journey of our client as long as they need
                    us..
                  </p>
                </div>
                <div className="absolute bottom-[-12px] right-[-12px]">
                  <h1 className="px-8 py-6 bg-[#1571CC] text-white rounded-full ">
                    5
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* form */}
        {model && (
          <div className="container-section mat-100 fixed top-[10px] lg:left-32 sm:left-0 shadow-2xl lg:w-[80%] sm:h-[10rem] sm:w-[100%] z-[9999]">
            <div
              className="absolute lg:top-5 sm:top-2 right-10 cursor-pointer z-50"
              onClick={() => setModel(!model)}
            >
              <span className="lg:text-4xl sm:text-2xl font-bold">
                <IoMdClose />
              </span>
            </div>
            <div className="footer-contact-section lg:p-[50px] sm:p-5">
              <div className="grid grid-cols-12">
                <div className="lg:col-span-6  md:col-span-6 sm:col-span-12">
                  <h6>CONTACT US</h6>
                  <h3 className="heading-h3 sm:hidden lg:block">
                    Time to break the ice?
                  </h3>
                  <p className="para sm:hidden lg:block">
                    We would love to hear from you.
                  </p>
                  <p className="mt-8 sm:hidden lg:block">
                    Please fill the form or send us an email at
                  </p>
                  <p
                    className="para para-red lg:mt-7 sm:mt-1"
                    style={{ fontSize: "25px" }}
                  >
                    <a href="mailto:sales@maxifyweb.com">info@digixtron.com</a>
                  </p>
                </div>
                <div className="lg:col-span-6  md:col-span-6 sm:col-span-12 sm:mt-5">
                  <div className="footer-contact-form">
                    <div className="two flex sm:flex-col justify-between">
                      <input
                        type="text"
                        placeholder="First Name"
                        name="fname"
                        id=""
                        required={true}
                        onChange={handleChange}
                        value={formData.fname}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="lname"
                        id=""
                        required={true}
                        onChange={handleChange}
                        value={formData.lname}
                      />
                    </div>
                    <div className="two flex sm:flex-col justify-between">
                      <input
                        type="email"
                        placeholder="Company Email"
                        name="email"
                        id=""
                        required={true}
                        onChange={handleChange}
                        value={formData.email}
                      />

                      <PhoneInput
                        country={"in"}
                        // enableSearch={true}
                        required={true}
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                        className="w-full"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Company"
                      name="cname"
                      required={true}
                      id=""
                      onChange={handleChange}
                      value={formData.cname}
                    />
                    <textarea
                      name="message"
                      id=""
                      required={true}
                      onChange={handleChange}
                      value={formData.message}
                      placeholder="Message"
                      cols="30"
                      rows=""
                    ></textarea>
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="sm:w-full text-[16px] p-4 rounded-md"
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* whats aap  */}
        <div className="contact fixed bottom-[5%] right-5 rounded-full z-50">
          <a
            href="https://wa.me/+91 96960 10032"
            target="_blank"
            className="flex items-center gap-2 bg-green-500 py-2 px-4 text-white text-xl rounded-full"
          >
            <span className="">
              <FaWhatsapp />
            </span>
            <span>How can I help you?</span>
            {/* <img
              className="w-14 p-1 rounded-full cursor-pointer"
              src={whatsapp}
              alt=""
            /> */}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
