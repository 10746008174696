import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import human from "../image/human.jpeg";
import ReactStars from "react-rating-stars-component";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./style.css";
import review1 from "../image/review1.jpg";
import review2 from "../image/review2.jpg";
import review3 from "../image/review3.jpg";
import review4 from "../image/review4.jpg";

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";

const Slider = () => {
  return (
    <>
      <Swiper
        effect={"coverflow"}
        // grabCursor={true}
        // centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          //   modifier: 1,
          //   slideShadows: true,
        }}
        loop={true}
        autoplay={true}
        pagination={true}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper"
      >
        {/* <Swiper watchSlidesProgress={true} slidesPerView={3} className="mySwiper"> */}
        <div className="flex gap-10">
          <SwiperSlide className="py-10 rounded-xl">
            <div className="flex flex-col gap-5">
              <div className="image flex justify-center ">
                <img
                  className="w-16 h-16 rounded-full items-center"
                  src={review2}
                  alt=""
                />
              </div>
              <h2 className="text-xl font-bold">Akansha Goswami, Delhi NCR</h2>
              <div className="text">
                <p className="text-base">
                  Excellent web development services! Your team created a
                  beautiful website and optimized it for SEO, boosting our
                  traffic and search ranking. Looking forward to future projects
                </p>
              </div>
              <div className="star flex justify-center gap-2">
                <ReactStars
                  count={4}
                  size={32}
                  activeColor="#ffd700"
                  value={4}
                  color={"#ffd700"}
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="py-10 rounded-xl">
            {" "}
            <div className="flex flex-col gap-5">
              <div className="image flex justify-center ">
                <img
                  className="w-16 h-16 rounded-full items-center"
                  src={review1}
                  alt=""
                />
              </div>
              <h2 className="text-xl font-bold">Divey Mahajan, Delhi NCR</h2>
              <div className="text">
                <p className="text-base">
                  Choosing your e-commerce development agency was the best move
                  for our Singapore-based business. The website you designed
                  looks slick, professional, and works effortlessly. With your
                  digital marketing expertise, our online store's revenues and
                  exposure have significantly increased. Highly recommended.
                </p>
              </div>
              <div className="star flex justify-center gap-2">
                <ReactStars
                  count={3.5}
                  size={32}
                  activeColor="#ffd700"
                  color2={"#ffd700"}
                  color={"#ffd700"}
                  value={3}
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="py-10 rounded-xl">
            {" "}
            <div className="flex flex-col gap-5">
              <div className="image flex justify-center ">
                <img
                  className="w-16 h-16 rounded-full items-center"
                  src={review3}
                  alt=""
                />
              </div>
              <h2 className="text-xl font-bold">Priyanshu Bansal, Noida</h2>
              <div className="text">
                <p className="text-base">
                  Excellent app development services! Your agency exceeded our
                  expectations, creating a user-friendly, visually appealing app
                  and using excellent SEO methods. I appreciate your
                  professionalism and dedication.
                </p>
              </div>
              <div className="star flex justify-center gap-2 ">
                <ReactStars
                  count={5}
                  size={32}
                  activeColor="#ffd700"
                  color2={"#ffd700"}
                  color={"#ffd700"}
                  value={5}
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="py-10 rounded-xl">
            {" "}
            <div className="flex flex-col gap-5">
              <div className="image flex justify-center ">
                <img
                  className="w-16 h-16 rounded-full items-center"
                  src={review4}
                  alt=""
                />
              </div>
              <h2 className="text-xl font-bold">
                {" "}
                Randeep Batra, Ghaziabad Delhi NCR
              </h2>
              <div className="text">
                <p className="text-base">
                  Five stars for your digital marketing services! Your agency's
                  expertise in SEO, social media marketing, and content
                  development has significantly increased our web visibility and
                  lead creation. We've seen a considerable increase in website
                  traffic and conversions, receiving good comments from clients.
                  Keep up the excellent work!
                </p>
              </div>
              <div className="star flex justify-center gap-2 ">
                <ReactStars
                  count={4.5}
                  size={32}
                  activeColor="#ffd700"
                  color2={"#ffd700"}
                  color={"#ffd700"}
                  value={5}
                />
              </div>
            </div>
          </SwiperSlide>
        </div>
      </Swiper>
    </>
  );
};

export default Slider;
