import React from "react";
import "./footer.css";
import { FaFacebookF } from "react-icons/fa";

import { IoLogoInstagram } from "react-icons/io5";
import { FaTwitter } from "react-icons/fa";
import { PiMapPinThin } from "react-icons/pi";

import { MdOutlineMailOutline } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";

import { FaCheckDouble } from "react-icons/fa6";
import { MdAddIcCall } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <footer className="">
        <div className="">
          <hr className="my-4 " />
          <div className="footer-copyrighte lg:mx-20 sm:5 sm:block lg:flex">
            <div>
              <p className="para sm:text-start sm:ml-5">
                © 2024 digixtron All rights reserved.
              </p>
            </div>
            <div className="lg:col-span-6  md:col-span-6 sm:col-span-12 lg:ml-20 sm:ml-0 lg:mr-52">
              <div className="footer-content">
                <div className="social-media-icon lg:ml-[102px] sm:ml-5">
                  <span>
                    <a href="https://www.facebook.com/digixtro" target="_blank">
                      <FaFacebookF />
                    </a>
                  </span>
                  <span>
                    <a
                      href="https://www.linkedin.com/in/digi-xtron-bb461326b/"
                      target="_blank"
                    >
                      <FaLinkedin />
                    </a>
                  </span>

                  <span>
                    <a
                      href="https://www.instagram.com/digixtron_/"
                      target="_blank"
                    >
                      <IoLogoInstagram />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
